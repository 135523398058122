//Specific JS for this skin
require(['jquery'], function ($) {
    $('#mini-cart .hide-below-960').html("<i class='fa fa-shopping-cart'></i>");
    $('.form-search .button-search').html("<i class='fa fa-search'></i>");
      // To ensure the footer stays at the bottom

      $(window).on("load", function() {
          const newh = $(window).outerHeight() - $('.header-container').outerHeight() - $('.main-container').outerHeight() - 	$('.footer-container').outerHeight();
          if (newh > 0) {
                  $('.main-container').css('min-height', $('.main-container').outerHeight() + newh +'px');
          }
        });
    //Change the placeholder text for Newletter module
  $('#news-letter-input_email').attr('placeholder','Email');
  //Change the layout for category pages to show 3 products in a row for devices > 767px and < 1200px;
  if($(window).width() > 767 && $(window).width() < 1440) {
    $('ul.category-products-listing.products-grid').removeClass('itemgrid-5col');
    $('ul.category-products-listing.products-grid').addClass('itemgrid-4col');
  }
  //Remove the hover effect
   $('.category-products-grid').removeClass('hover-effect');
  $('.category-products-grid li').each(function() {
   $('.product-shop-inner .display-onhover',this).remove(); 
  });
});
